import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {SectionNotification, SectionNotificationType} from 'wix-ui-tpa';
import Alert from 'wix-ui-icons-common/on-stage/Alert';
import Error from 'wix-ui-icons-common/on-stage/Error';
import {Severity} from '@wix/wixstores-graphql-schema-node';
import s from './Violations.scss';

export enum ViolationsDataHook {
  Root = 'ViolationsDataHook.Root',
  Violation = 'ViolationsDataHook.Violation',
  Message = 'ViolationsDataHook.Message',
}

export const Violations = () => {
  const {
    cartStore: {
      cart: {violations},
    },
  } = useControllerProps();
  const violationsToRender = [
    ...violations.filter((violation) => violation.severity === Severity.ERROR),
    ...violations.filter((violation) => violation.severity === Severity.WARNING),
  ]
    .slice(0, 3)
    .reverse();

  return (
    <div data-hook={ViolationsDataHook.Root}>
      {violationsToRender.map((violation, index) => {
        const type =
          violation.severity === Severity.ERROR ? SectionNotificationType.error : SectionNotificationType.alert;
        const Icon = violation.severity === Severity.ERROR ? Error : Alert;

        return (
          <div key={index} className={s.notification}>
            <SectionNotification type={type} data-hook={ViolationsDataHook.Violation}>
              <SectionNotification.Icon icon={<Icon className={s.icon} />} />
              <SectionNotification.Text>
                <span data-hook={ViolationsDataHook.Message}>{violation.description}</span>
              </SectionNotification.Text>
            </SectionNotification>
          </div>
        );
      })}
    </div>
  );
};
